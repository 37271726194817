<template>
  <div class="container privacy-policy">
    <div class="col-12">
      <h6 class="text-blue mb-5">Datenschutzinformationen</h6>
      <ol>
        <li>
          <h6 class="text-blue mb-3">
            Name und Kontaktdaten des für die Verarbeitung Verantwortlichen
          </h6>
          <p class="mb-3">
            Diese Datenschutz-Information gilt für die Datenverarbeitung durch
            IHGP STEUER GmbH Steuerberatungsgesellschaft, Oranienstraße 155,
            10969 Berlin, E-Mail: kanzlei@ihgp-steuer.de, sowie für folgende
            Internetseite: www.flinktax.de.
          </p>
        </li>
        <li>
          <h6 class="text-blue mb-3">Aufrufen unserer Website</h6>
          <ol>
            <li>
              <div>
                <p class="mb-3">
                  Beim Aufrufen unserer Website werden durch den von Ihnen
                  benutzten Browser automatisch Informationen an den Server
                  unserer Website gesendet und temporär in einem Log-File
                  gespeichert. Folgende Informationen werden dabei ohne Ihr
                  Zutun erfasst und bis zur automatisierten Löschung
                  gespeichert:
                </p>
                <ul>
                  <li>die IP-Adresse des anfragenden Rechners,</li>
                  <li>das Datum und die Uhrzeit des Zugriffs,</li>
                  <li>der Name und die URL der abgerufenen Datei,</li>
                  <li>
                    die Website, von der aus der Zugriff erfolgte
                    (Referrer-URL),
                  </li>
                  <li>
                    der von Ihnen verwendete Browser und ggfs. das
                    Betriebssystem Ihres Rechners sowie der Name Ihres
                    Access-Providers.
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <p>
                Die aufgelisteten Daten werden von uns erhoben und verarbeitet,
                um einen reibungslosen Verbindungsaufbau und eine komfortable
                Nutzung unserer Website zu gewährleisten. Darüber hinaus dienen
                sie uns zur Auswertung der Systemsicherheit und -stabilität
                sowie zu administrativen Zwecken, um unsere Leistungen stets
                verbessern zu können.
              </p>
            </li>
            <li>
              <p>
                Die Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1
                S. 1 lit. f DSGVO. Unser berechtigtes Interesse folgt aus oben
                aufgelisteten Zwecken zur Datenerhebung. In keinem Fall
                verwenden wir die erhobenen Daten zu dem Zweck, Rückschlüsse auf
                Ihre Person zu ziehen. Die Daten werden für einen Zeitraum von 7
                Tagen gespeichert und danach automatisch gelöscht.
              </p>
            </li>
            <li>
              <p>
                Der Betrieb unseres Online-Angebots erfolgt über einen
                Hosting-Dienstleistungsanbieter mit Sitz innerhalb der EU. Der
                Anbieter, mit dem wir eine Vereinbarung zur Auftragsverarbeitung
                gemäß Art. 28 DSGVO abgeschlossen haben, stellt uns
                Infrastruktur und Speicherplatz für unsere Website und E-
                Mail-Postfächer auf seinen Servern innerhalb der EU zur
                Verfügung und übernimmt die Wartung, den technischen Support
                sowie den Anlagenbetrieb. Hierbei werden vom Anbieter in unserem
                Auftrag personenbezogene Daten verarbeitet, die Sie uns bei der
                Nutzung unserer Dienste mitteilen, zum Beispiel Ihr Name und
                Ihre E-Mail-Adresse für die Vertragsdurchführung. Für einen
                reibungslosen und sicheren Betrieb unseres Online-Angebots zu
                sorgen stellt zudem unser berechtigtes Interesse gemäß Art. 6
                Abs. 1 S. 1 f DSGVO dar.
              </p>
            </li>
            <li>
              <p>
                Des Weiteren verwenden wir für unsere Website Cookies und
                Tracking-Tools. Um was für Verfahren es sich dabei genau handelt
                und wie ihre Daten dafür verwendet werden, wird unter den
                entsprechenden Ziffern genauer erläutert.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h6 class="text-blue mb-3">Cookies</h6>
          <ol>
            <li>
              <p>
                Wir setzen auf unserer Website sogenannte Cookies ein. Hierbei
                handelt es sich um kleine Dateien, die Ihr Browser automatisch
                erstellt und die auf Ihrem Endgerät (Laptop, Tablet, Smartphone
                o.ä.) gespeichert werden, wenn Sie unsere Seite besuchen.
                Cookies richten auf Ihrem Endgerät keinen Schaden an, enthalten
                keine Viren, Trojaner oder sonstige Schadsoftware. In dem Cookie
                werden Informationen abgelegt, die sich jeweils im Zusammenhang
                mit dem spezifisch eingesetzten Endgerät ergeben. Dies bedeutet
                jedoch nicht, dass wir dadurch unmittelbar Kenntnis von Ihrer
                Identität erhalten. Der Einsatz von Cookies dient einerseits
                dazu, die Nutzung unseres Angebots für Sie angenehmer zu
                gestalten. So setzen wir sogenannte Session-Cookies ein, um zu
                erkennen, dass Sie einzelne Seiten unserer Website bereits
                besucht haben. Diese werden nach Verlassen unserer Seite
                automatisch gelöscht. Rechtsgrundlage für den Einsatz von
                Cookies ist Art. 6 Abs. 1 S. 1 lit. a) DSGVO, soweit Sie uns
                eine Einwilligung erteilt haben. Für notwendige Cookies, ohne
                deren Einsatz der Betrieb unserer Webseite nicht möglich wäre,
                ist Rechtsgrundlage Art. 6 Abs. 1 S. 1 lit. f) DSGVO. Der Zweck
                ist der Betrieb unserer Webseite. Dieser Zweck ist rechtmäßig
                und stellt ein berechtigtes Interesse unsererseits dar.
              </p>
            </li>
            <li>
              <p>
                Darüber hinaus setzen wir ebenfalls zum Zweck der
                Benutzerfreundlichkeit temporäre Cookies ein, die für einen
                bestimmten festgelegten Zeitraum auf Ihrem Endgerät gespeichert
                werden. Besuchen Sie unsere Seite erneut, um unsere Dienste in
                Anspruch zu nehmen, wird automatisch erkannt, dass Sie bereits
                bei uns waren und welche Eingaben und Einstellungen sie getätigt
                haben, um diese nicht noch einmal eingeben zu müssen. Diese
                Cookies setzen wir nicht ohne Ihre ausdrückliche Einwilligung.
                Rechtsgrundlage ist Art. 6 Abs. 1 lit. a) DSGVO. Ihre
                Einwilligung können Sie jederzeit mit Wirkung für die Zukunft
                widerrufen. Der Widerruf erfolgt auf die gleiche Weise, wie Sie
                Ihre Einwilligung erteilt. Klicken Sie dazu auf das
                entsprechende Symbol auf unserer Webseite und ändern Sie ganz
                einfach Ihre ausgewählten Einstellungen.
              </p>
            </li>
            <li>
              <p>
                Zum anderen setzen wir Cookies ein, um die Nutzung unserer
                Website statistisch zu erfassen und um unser Angebot für Sie zu
                optimieren und auszuwerten. Diese Cookies ermöglichen es uns,
                bei einem erneuten Besuch unserer Seite automatisch zu erkennen,
                dass Sie bereits bei uns waren. Diese Cookies werden nach einer
                jeweils definierten Zeit automatisch gelöscht. Die meisten
                Browser akzeptieren Cookies automatisch. Sie können Ihren
                Browser jedoch so konfigurieren, dass keine Cookies auf Ihrem
                Computer gespeichert werden oder stets ein Hinweis erscheint,
                bevor ein neues Cookie angelegt wird. Die vollständige
                Deaktivierung von Cookies kann jedoch dazu führen, dass Sie
                nicht alle Funktionen unserer Website nutzen können. Die
                Speicherdauer der Cookies ist abhängig von ihrem
                Verwendungszweck und nicht für alle gleich. Diese Cookies setzen
                wir nicht ohne Ihre ausdrückliche Einwilligung. Rechtsgrundlage
                ist Art. 6 Abs. 1 lit. a) DSGVO. Ihre Einwilligung können Sie
                jederzeit mit Wirkung für die Zukunft widerrufen. Der Widerruf
                erfolgt auf die gleiche Weise, wie Sie Ihre Einwilligung
                erteilt. Klicken Sie dazu auf das entsprechende Symbol auf
                unserer Webseite und ändern Sie ganz einfach Ihre ausgewählten
                Einstellungen.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h6 class="text-blue mb-3">Google Analytics</h6>
          <ol>
            <li>
              <p>
                Zum Zwecke der bedarfsgerechten Gestaltung und fortlaufenden
                Optimierung unserer Seiten nutzen wir auf Google Analytics, ein
                Webanalysedienst der Google Inc. (1600 Amphitheatre Parkway,
                Mountain View, CA 94043, United States). Zum anderen setzen wir
                die Tracking-Maßnahmen ein, um die Nutzung unserer Webseite
                statistisch zu erfassen und zum Zwecke der Optimierung unseres
                Angebotes für Sie auszuwerten. Diese Interessen sind als
                berechtigt im Sinne des Art. 6 Abs. 1 lit. f) DSGVO anzusehen.
              </p>
            </li>
            <li>
              <p>
                Sofern in diesem Zusammenhang Daten verarbeitet werden, erfolgt
                dies auf der Grundlage von Art. 6 Abs. 1 lit. f) DSGVO, sofern
                für die Verarbeitung im Einzelnen nicht Ihre Einwilligung
                erforderlich ist. Soweit Ihre Einwilligung erforderlich ist,
                erfolgt die Verarbeitung nicht ohne Ihre Einwilligung.
                Rechtsgrundlage ist Art. 6 Abs. 1 lit. a) DSGVO. Ihre
                Einwilligung können Sie jederzeit mit Wirkung für die Zukunft
                widerrufen. Der Widerruf erfolgt auf die gleiche Weise, wie Sie
                Ihre Einwilligung erteilt. Klicken Sie dazu auf das
                entsprechende Symbol auf unserer Webseite und ändern Sie ganz
                einfach Ihre ausgewählten Einstellungen.
              </p>
            </li>
            <li>
              <div>
                <p>
                  In diesem Zusammenhang werden pseudonymisierte Nutzungsprofile
                  erstellt und Cookies verwendet. Die durch das Cookie erzeugten
                  Informationen über Ihre Benutzung dieser Website wie
                </p>
                <ul>
                  <li>Browser-Typ/-Version,</li>
                  <li>verwendetes Betriebssystem,</li>
                  <li>Referrer-URL (die zuvor besuchte Seite),</li>
                  <li>Hostname des zugreifenden Rechners (IP-Adresse),</li>
                  <li>Uhrzeit der Serveranfrage,</li>
                </ul>
                werden an einen Server von Google in den USA übertragen und dort
                gespeichert. Die Informationen werden verwendet, um die Nutzung
                der Website auszuwerten, um Reports über die Websiteaktivitäten
                zusammenzustellen und um weitere mit der Websitenutzung und der
                Internetnutzung verbundene Dienstleistungen zu Zwecken der
                Marktforschung und bedarfsgerechten Gestaltung dieser
                Internetseiten zu erbringen. Auch werden diese Informationen
                gegebenenfalls an Dritte übertragen, sofern dies gesetzlich
                vorgeschrieben ist oder soweit Dritte diese Daten im Auftrag
                verarbeiten. Es wird in keinem Fall Ihre IP-Adresse mit anderen
                Daten von Google zusammengeführt. Die IP-Adressen werden
                anonymisiert, so dass eine Zuordnung zu Ihrer Person nicht
                möglich ist (IP-Masking). Die Anonymisierung findet in der Regel
                innerhalb von Mitgliedstaaten der Europäischen Union oder in
                anderen Vertragsstaaten des Abkommens über den Europäischen
                Wirtschaftsraum statt. Nur in Ausnahmefällen wird die volle
                IP-Adresse an einen Server von Google in den USA übertragen und
                dort gekürzt.
              </div>
            </li>
            <li>
              <p>
                Sie können die Installation der Cookies durch eine entsprechende
                Einstellung der Browser- Software verhindern; wir weisen jedoch
                darauf hin, dass in diesem Fall gegebenenfalls nicht sämtliche
                Funktionen dieser Website vollumfänglich genutzt werden können.
                Sie können darüber hinaus die Erfassung der durch das Cookie
                erzeugten und auf Ihre Nutzung der Website bezogenen Daten
                (inkl. Ihrer IP-Adresse) sowie die Verarbeitung dieser Daten
                durch Google verhindern, indem Sie dieses Browser-Add-on
                herunterladen und installieren:
                <span class="break-word"
                  >https://tools.google.com/dlpage/gaoptout?hl=de.</span
                >
              </p>
            </li>
            <li>
              Alternativ zum Browser-Add-on, insbesondere bei Browsern auf
              mobilen Endgeräten, können Sie die Erfassung durch Google
              Analytics zudem verhindern, indem Sie auf diesen Link klicken. Es
              wird ein Opt-Out-Cookie gesetzt, das die zukünftige Erfassung
              Ihrer Daten beim Besuch dieser Website verhindert. Das
              Opt-Out-Cookie gilt nur in diesem Browser und nur für unsere
              Website und wird auf Ihrem Gerät abgelegt. Löschen Sie die Cookies
              in diesem Browser, müssen Sie das Opt-Out-Cookie erneut setzen.
              Weitere Informationen zum Datenschutz im Zusammenhang mit Google
              Analytics finden Sie auf der Webseite von Google Analytics.
            </li>
            <li>
              Google Inc. hat seinen Sitz in den USA und damit außerhalb der
              Europäischen Union. Es existiert kein Angemessenheitsbeschluss der
              EU-Kommission und auch keine sonstige gesetzliche Grundlage auf
              zwischenstaatlicher Ebene. Wir haben allerdings mit der Google
              Inc. einen Vertrag über die Auftragsverarbeitung geschlossen.
              Dieser Vertrag richtet sich nach den von der EU-Kommission
              beschlossenen Standardvertragsklauseln. Danach ist Google Inc.
              verpflichtet, ein innerhalb der EU vergleichbares, angemessenes
              Datenschutzniveau für die Verarbeitung personenbezogener Daten zu
              schaffen.
            </li>
          </ol>
        </li>
        <li>
          <h6 class="text-blue mb-3">E-Mail-Kontakt und Kontaktformulare</h6>
          <ol>
            <li>
              <p>
                Auf unserer Internetseite ist eine Kontaktaufnahme über die
                bereitgestellte E-Mail-Adresse möglich. In diesem Fall werden
                Ihre mit der E-Mail übermittelten personenbezogenen Daten
                gespeichert. Es erfolgt in diesem Zusammenhang keine Weitergabe
                der Daten an Dritte. Die Daten werden ausschließlich für die
                Verarbeitung der Konversation verwendet.
              </p>
            </li>
            <li>
              <p>
                Rechtsgrundlage für die Verarbeitung der Daten ist bei Vorliegen
                einer Einwilligung des Nutzers Art. 6 Abs. 1 lit. a DSGVO.
                Rechtsgrundlage für die Verarbeitung der Daten, die im Zuge
                einer Übersendung einer E-Mail übermittelt werden, ist Art. 6
                Abs. 1 lit. f DSGVO. Zielt der E-Mail-Kontakt auf den Abschluss
                eines Vertrages ab, so ist Rechtsgrundlage für die Verarbeitung
                Art. 6 Abs. 1 lit. b DSGVO. Im Falle einer Kontaktaufnahme per
                E-Mail liegt hieran auch das erforderliche berechtigte Interesse
                an der Verarbeitung der Daten.
              </p>
            </li>
            <li>
              <p>
                Die Daten werden gelöscht, sobald sie für die Erreichung des
                Zweckes ihrer Erhebung nicht mehr erforderlich sind. Für die
                personenbezogenen Daten, die per E-Mail übersandt wurden, ist
                dies dann der Fall, wenn die jeweilige Konversation mit dem
                Nutzer beendet ist. Beendet ist die Konversation dann, wenn sich
                aus den Umständen entnehmen lässt, dass der betroffene
                Sachverhalt abschließend geklärt ist.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h6 class="text-blue mb-3">Newsletter</h6>
          <ol>
            <li>
              <p>
                Wenn Sie sich auf unserer Webseite für unseren Newsletter
                angemeldet haben, wird Ihre E-Mail- Adresse für den Versand
                eines Newsletters von uns verwendet. Über den Newsletter wird
                ausschließlich Direktwerbung für eigene ähnliche Produkte sowie
                für eigene ähnliche Dienstleistungen versendet.
              </p>
            </li>
            <li>
              <p>
                Im Zusammenhang mit der Datenverarbeitung für den Versand von
                Newslettern werden Ihre Daten an einen externen Dienstleister
                übermittelt, den wir mit dem koordinierten Versand unseres
                Newsletters betraut haben. Dieser Dienstleister ist in den USA
                und damit außerhalb der Europäischen Union ansässig. Es
                existiert kein Angemessenheitsbeschluss der EU-Kommission und
                auch keine sonstige gesetzliche Grundlage auf
                zwischenstaatlicher Ebene. Wir haben allerdings mit dem Anbieter
                einen Vertrag über die Auftragsverarbeitung geschlossen. Dieser
                Vertrag richtet sich nach den von der EU-Kommission
                beschlossenen Standardvertragsklauseln. Danach ist der Anbieter
                verpflichtet, ein innerhalb der EU vergleichbares, angemessenes
                Datenschutzniveau für die Verarbeitung personenbezogener Daten
                zu schaffen.
              </p>
            </li>
            <li>
              <p>
                Rechtsgrundlage für die Verarbeitung der Daten nach Anmeldung
                zum Newsletter durch den Nutzer ist bei Vorliegen einer
                Einwilligung des Nutzers Art. 6 Abs. 1 lit. a DSGVO.
                Rechtsgrundlage für den Versand des Newsletters infolge des
                Verkaufs von Waren oder Dienstleistungen ist § 7 Abs. 3 UWG bzw.
                Art. 6 Abs. 1 lit f) DSGVO. Die Erhebung der E-Mail-Adresse des
                Nutzers dient dazu, den Newsletter zuzustellen. Die Erhebung
                sonstiger personenbezogener Daten im Rahmen des Anmeldevorgangs
                dient dazu, einen Missbrauch der Dienste oder der verwendeten
                E-Mail-Adresse zu verhindern.
              </p>
            </li>
            <li>
              <p>
                Die Daten werden gelöscht, sobald sie für die Erreichung des
                Zweckes ihrer Erhebung nicht mehr erforderlich sind. Die
                E-Mail-Adresse des Nutzers wird demnach solange gespeichert, wie
                das Abonnement des Newsletters aktiv ist.
              </p>
            </li>
            <li>
              <p>
                Das Abonnement des Newsletters kann durch den betroffenen Nutzer
                jederzeit gekündigt werden. Zu diesem Zweck findet sich in jedem
                Newsletter ein entsprechender Link. Hierdurch wird ebenfalls ein
                Widerruf der Einwilligung der Speicherung der während des
                Anmeldevorgangs erhobenen personenbezogenen Daten ermöglicht.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h6 class="text-blue mb-3">
            Social Media-Fanpages: Facebook, X, LinkedIn
          </h6>
          <ol>
            <li>
              <p>
                Wir betreiben in den sozialen Netzwerken LinkedIn, X (vormals
                Twitter), Facebook und Instagram sog. Fanpages. Auf diesen
                Fanpages ist es uns mit dem von den sozialen Netzwerken zur
                Verfügung gestellten Diensten möglich, bestimmte Daten über die
                Besucher unserer Fanpages zu ermitteln und auszuwerten. Zu
                diesen Daten gehören u.a. Aufschlüsselungen nach Altersgruppe,
                Geschlecht, Bildungsstand, Beruf, sofern Besucher unserer
                Facebook-Seite diese Angaben in ihrem Facebook- Profil gemacht
                haben.
              </p>
            </li>
            <li>
              <p>
                Für die Verarbeitung der Daten besteht eine gemeinsame
                Verantwortlichkeit mit dem jeweiligen sozialen Netzwerk.
                Rechtsgrundlage für die Verarbeitung durch uns ist Art. 6 Abs. 1
                lit. f) DSGVO. Zweck der Nutzung ist die bedarfsgerechten
                Gestaltung und fortlaufenden Optimierung unseres Angebots sowie
                die bessere Kommunikation mit unseren Kunden und Interessenten.
                Unser berechtigtes Interesse folgt aus den Zwecken zur
                Datenerhebung.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h6 class="text-blue mb-3">Google Maps</h6>
          <ol>
            <li>
              <p>
                Auf unserer Website verwenden wir Google Maps (API) von Google
                LLC., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA
                (“Google”). Google Maps ist ein Webdienst zur Darstellung von
                interaktiven (Land-)Karten, um geographische Informationen
                visuell darzustellen. Über die Nutzung dieses Dienstes wird
                Ihnen unser Standort angezeigt und eine etwaige Anfahrt
                erleichtert.
              </p>
            </li>
            <li>
              <p>
                Bereits beim Aufrufen derjenigen Unterseiten, in die die Karte
                von Google Maps eingebunden ist, werden Informationen über Ihre
                Nutzung unserer Website (wie z.B. Ihre IP-Adresse) an Server von
                Google in den USA übertragen und dort gespeichert. Dies erfolgt
                unabhängig davon, ob Google ein Nutzerkonto bereitstellt, über
                das Sie eingeloggt sind, oder ob kein Nutzerkonto besteht. Wenn
                Sie bei Google eingeloggt sind, werden Ihre Daten direkt Ihrem
                Konto zugeordnet. Wenn Sie die Zuordnung mit Ihrem Profil bei
                Google nicht wünschen, müssen Sie sich vor Aktivierung des
                Buttons ausloggen. Google speichert Ihre Daten (selbst für nicht
                eingeloggte Nutzer) als Nutzungsprofile und wertet diese aus.
                Eine solche Auswertung erfolgt insbesondere gemäß Art. 6 Abs. 1
                lit.f) DSGVO auf Basis der berechtigten Interessen von Google an
                der Einblendung personalisierter Werbung, Marktforschung
                und/oder bedarfsgerechten Gestaltung seiner Website. Ihnen steht
                ein Widerspruchsrecht zu gegen die Bildung dieser Nutzerprofile,
                wobei Sie sich zur Ausübung dessen an Google richten müssen.
              </p>
            </li>
            <li>
              <p>
                Google Inc. hat seinen Sitz in den USA und damit außerhalb der
                Europäischen Union. Es existiert kein Angemessenheitsbeschluss
                der EU-Kommission und auch keine sonstige gesetzliche Grundlage
                auf zwischenstaatlicher Ebene. Wir haben allerdings mit der
                Google Inc. einen Vertrag über die Auftragsverarbeitung
                geschlossen. Dieser Vertrag richtet sich nach den von der
                EU-Kommission beschlossenen Standardvertragsklauseln. Danach ist
                Google Inc. verpflichtet, ein innerhalb der EU vergleichbares,
                angemessenes Datenschutzniveau für die Verarbeitung
                personenbezogener Daten zu schaffen.
              </p>
            </li>
            <li>
              <p>
                Wenn Sie mit der künftigen Übermittlung Ihrer Daten an Google im
                Rahmen der Nutzung von Google Maps nicht einverstanden sind,
                besteht auch die Möglichkeit, den Webdienst von Google Maps
                vollständig zu deaktivieren, indem Sie die Anwendung JavaScript
                in Ihrem Browser ausschalten. Google Maps und damit auch die
                Kartenanzeige auf dieser Internetseite kann dann nicht genutzt
                werden.
              </p>
            </li>
            <li>
              <p>
                Die Nutzungsbedingungen von Google können Sie unter
                <span class="break-word"
                  >http://www.google.de/intl/de/policies/terms/regional.html</span
                >
                einsehen, die zusätzlichen Nutzungsbedingungen für Google Maps
                finden Sie unter
                <span class="break-word"
                  >https://www.google.com/intl/de_US/help/terms_maps.html.</span
                >
                Ausführliche Informationen zum Datenschutz im Zusammenhang mit
                der Verwendung von Google Maps finden Sie auf der Internetseite
                von Google („Google Privacy Policy“):
                <span class="break-word"
                  >http://www.google.de/intl/de/policies/privacy/.</span
                >
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h6 class="text-blue mb-3">PayPal</h6>
          <p>
            Wenn Sie im Rahmen Ihrer Bestellung die Zahlungsart PayPal wählen,
            werden Ihre Zahlungsinformationen zur Abwicklung des
            Zahlungsvorgangs gemäß Art. 6 Abs. 1 lit. b) DSGVO an „PayPal“
            (PayPal (Europe) S.a.r.l. et Cie, S.C.A., 22-24 Boulevard Royal,
            L-2449 Luxembourg) weitergegeben. PayPal bietet Ihnen für die
            Zahlungsabwicklung unterschiedliche Möglichkeiten, die sich nach den
            von Ihnen in Ihrem PayPal Konto hinterlegten Einstellungen richten.
            Für ausgewählte Zahlungsmethoden (Lastschrift via PayPal,
            Kreditkarte via PayPal, Kauf auf Rechnung via PayPal, Ratenzahlung
            via PayPal) behält sich PayPal vor, eine Bonitätsprüfung
            durchzuführen. Zu diesem Zweck werden Ihre Zahlungsinformationen
            gegebenenfalls gemäß Art. 6 Abs. 1 lit. f) DSGVO an entsprechende
            Auskunfteien weitergegeben. Weiterführende Informationen zur
            Verarbeitung Ihrer Daten durch PayPal erhalten Sie in der
            Datenschutzerklärung des Zahlungsdienstleisters:
            <span class="break-word"
              >https://www.paypal.com/de/webapps/mpp/ua/privacy-full.</span
            >
          </p>
        </li>
        <li>
          <h6 class="text-blue mb-3">Stripe</h6>
          <p>
            Wenn Sie im Rahmen Ihrer Bestellung die Zahlungsart PayPal wählen,
            werden Ihre Zahlungsinformationen zur Abwicklung des
            Zahlungsvorgangs gemäß Art. 6 Abs. 1 lit. b) DSGVO an „Stripe
            Payments Europe, Limited (SPEL), 1 Grand Canal Street Lower, Grand
            Canal Dock, Dublin, D02 H210, Irland) weitergegeben. Stripe bietet
            Ihnen für die Zahlungsabwicklung unterschiedliche Möglichkeiten, die
            sich nach den von Ihnen in Ihrem Stripe Konto hinterlegten
            Einstellungen richten. Für ausgewählte Zahlungsmethoden (Lastschrift
            via Stripe, Kreditkarte via Stripe, Kauf auf Rechnung via Stripe,
            Ratenzahlung via Stripe) behält sich Stripe vor, eine
            Bonitätsprüfung durchzuführen. Zu diesem Zweck werden Ihre
            Zahlungsinformationen gegebenenfalls gemäß Art. 6 Abs. 1 lit. f)
            DSGVO an entsprechende Auskunfteien weitergegeben. Weiterführende
            Informationen zur Verarbeitung Ihrer Daten durch PayPal erhalten Sie
            in der Datenschutzerklärung des Zahlungsdienstleisters:
            https://stripe.com/de/privacy.
          </p>
        </li>
        <li>
          <h6 class="text-blue mb-3">Vertragsschluss</h6>
          <ol>
            <li>
              <div>
                <p>
                  Wenn Sie sich bei uns anmelden, um die Dienste unserer
                  Plattform in Anspruch zu nehmen, Ihre Einkommenssteuerlast
                  schätzen lassen, Ihre Einkommenssteuererklärung erstellen
                  lassen oder uns mit der Übermittlung Ihrer Steuererklärung an
                  das für Sie zuständige Finanzamt beauftragen wollen, , dann
                  erheben wir folgende Informationen::
                </p>
                <ul>
                  <li>
                    Kontakt- und AdressdatenKontakt- und Adressdaten
                    <ul>
                      <li>Adresse, Hausnummer, PLZ und Ort</li>
                      <li>Email</li>
                      <li>Telefonnummer</li>
                      <li>Familienstand</li>
                    </ul>
                  </li>
                  <li>Steueridentifikationsnummer</li>
                  <li>IBAN</li>
                  <li>
                    Informationen, die für die erfolgreiche Durchführung des
                    Vertrages notwendig sind.
                  </li>
                </ul>
                <p class="mt-3">
                  Um den mit Ihnen geschlossenen Vertrag ordnungsgemäß erfüllen
                  zu können, müssen Sie zudem folgende Unterlagen und Dokumente
                  an uns elektronisch übermitteln:
                </p>
                <ul>
                  <li>Lohnsteuerbescheinigung</li>
                  <li>Unterlagen zu Kapitalerträgen</li>
                  <li>Werbungskosten</li>
                  <li>Umzugskosten</li>
                  <li>Versicherungen</li>
                  <li>Spenden</li>
                  <li>Beiträge</li>
                  <li>Sonstige steuerrelevante Unterlagen</li>
                </ul>
              </div>
            </li>
            <li>
              <div>
                <p>Die Erhebung dieser Daten erfolgt,</p>
                <ul>
                  <li>um Sie als unseren Kunden identifizieren zu können;</li>
                  <li>
                    um unsere Leistungen für Sie zu erbringen und den Vertrag
                    erfüllen zu können;
                  </li>
                  <li>zur Korrespondenz mit Ihnen;</li>
                  <li>ggfs. zur Rechnungsstellung;</li>
                  <li>
                    zur Abwicklung von evtl. vorliegenden Haftungsansprüchen
                    sowie der Geltendmachung etwaiger Ansprüche gegen Sie;
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <p>
                Die Datenverarbeitung erfolgt auf Ihre Anfrage hin und ist nach
                Art. 6 Abs. 1 S. 1 lit. b DSGVO zu den genannten Zwecken für die
                angemessene Bearbeitung Ihrer Beauftragung und für die
                beidseitige Erfüllung von Verpflichtungen aus dem Vertrag
                erforderlich.
              </p>
            </li>
            <li>
              <p>
                Sofern Sie uns zum Zwecken der erfolgreichen Vertragserfüllung
                weitere Daten zur Verfügung gestellt und weitere Angaben über
                sich gemacht haben, kann dies ggfs. auch aufgrund einer
                Einwilligung erfolgen, die Sie uns erteilen. Dies betrifft all
                jene Daten, die für die Erfüllung unseres Leistungsangebotes
                nicht erforderlich sind. Sofern Sie uns eine Einwilligung
                erteilen, ist Rechtsgrundlage hierfür Art. 6 Abs. 1 lit. a)
                DSGVO. Ihre Einwilligung können Sie jederzeit mit Wirkung für
                die Zukunft uns gegenüber widerrufen.
              </p>
            </li>
            <li>
              <p>
                Sofern Sie uns zum Zwecken der erfolgreichen Vertragserfüllung
                weitere Daten zur Verfügung gestellt und weitere Angaben über
                sich gemacht haben, kann dies ggfs. auch aufgrund einer
                Einwilligung erfolgen, die Sie uns erteilen. Dies betrifft all
                jene Daten, die für die Erfüllung unseres Leistungsangebotes
                nicht erforderlich sind. Sofern Sie uns eine Einwilligung
                erteilen, ist Rechtsgrundlage hierfür Art. 6 Abs. 1 lit. a)
                DSGVO. Ihre Einwilligung können Sie jederzeit mit Wirkung für
                die Zukunft uns gegenüber widerrufen.
              </p>
            </li>
            <li>
              <p>
                Sofern Sie uns zum Zwecken der erfolgreichen Vertragserfüllung
                weitere Daten zur Verfügung gestellt und weitere Angaben über
                sich gemacht haben, kann dies ggfs. auch aufgrund einer
                Einwilligung erfolgen, die Sie uns erteilen. Dies betrifft all
                jene Daten, die für die Erfüllung unseres Leistungsangebotes
                nicht erforderlich sind. Sofern Sie uns eine Einwilligung
                erteilen, ist Rechtsgrundlage hierfür Art. 6 Abs. 1 lit. a)
                DSGVO. Ihre Einwilligung können Sie jederzeit mit Wirkung für
                die Zukunft uns gegenüber widerrufen.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h6 class="text-blue mb-3">Weitergabe von Daten an Dritte</h6>
          <ol>
            <li>
              <p>
                Eine Übermittlung Ihrer persönlichen Daten an Dritte zu anderen
                als den bereits genannten oder im Folgenden aufgeführten Zwecken
                findet nicht statt.
              </p>
            </li>
            <li>
              <p>
                Zur Durchführung und Abwicklung des Vertrages geben wir Ihre
                Adressdaten an das für Sie zuständige Finanzamt weiter. Die
                Übermittlung der Daten erfolgt über ELSTER, dem digitalen Portal
                der deutschen Finanzverwaltung. Rechtsgrundlage hierfür ist
                Artikel 6 Abs. 1 lit. b) DSGVO. Die weitergegebenen Daten dürfen
                von den Dritten ausschließlich zu den genannten Zwecken
                verwendet werden.
              </p>
            </li>
            <li>
              <p>
                Mit der Speicherung unserer Daten beauftragen wir einen externen
                Anbieter mit Sitz innerhalb der EU. Ihre Daten werden zum Zweck
                des koordinierten Arbeitens und der effizienten Gestaltung
                unserer Arbeitsabläufe sowie zum Zweck der Datensicherung an
                einen externen Dienstleister übermittelt. Rechtsgrundlagen
                hierfür ist Art. 6 Abs. 1 lit. f) DSGVO. Die effiziente
                Organisation von Arbeitsabläufen ist als ein berechtigtes
                Interesse im Sinne dieser Vorschrift anzusehen.
              </p>
            </li>
            <li>
              <p>
                Mit der Betreuung unserer EDV-Anlage beauftragen wir externe
                IT-Dienstleister. Rechtsgrundlagen hierfür ist Art. 6 Absatz 1
                lit. f) DSGVO. Die fachmännische Betreuung unseres EDV- Systems
                sowie Verarbeitung von Kundendaten sind als ein berechtigtes
                Interesse im Sinne dieser Vorschrift anzusehen.
              </p>
            </li>
            <li>
              <p>
                Für den Versand von E-Mails haben wir zudem einen externen
                Mailing-Dienstleister mit Sitz innerhalb der EU beauftragt.
                Rechtsgrundlagen hierfür ist Art. 6 Absatz 1 lit. f) DSGVO. Der
                fachmännische und reibungslose Versand von E-Mails ist als ein
                berechtigtes Interesse im Sinne dieser Vorschrift anzusehen.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h6 class="text-blue mb-3">Betroffenenrechte</h6>
          <p>Sie haben das Recht:</p>
          <div>
            <ul>
              <li>
                <p>
                  gemäß Art. 7 Abs. 3 DSGVO Ihre einmal erteilte Einwilligung
                  jederzeit gegenüber uns zu widerrufen. Dies hat zur Folge,
                  dass wir die Datenverarbeitung, die auf dieser Einwilligung
                  beruhte, für die Zukunft nicht mehr fortführen dürfen;
                </p>
              </li>
              <li>
                <p>
                  gemäß Art. 15 DSGVO Auskunft über Ihre von uns verarbeiteten
                  personenbezogenen Daten zu verlangen. Insbesondere können Sie
                  Auskunft über die Verarbeitungszwecke, die Kategorie der
                  personenbezogenen Daten, die Kategorien von Empfängern,
                  gegenüber denen Ihre Daten offengelegt wurden oder werden, die
                  geplante Speicherdauer, das Bestehen eines Rechts auf
                  Berichtigung, Löschung, Einschränkung der Verarbeitung oder
                  Widerspruch, das Bestehen eines Beschwerderechts, die Herkunft
                  ihrer Daten, sofern diese nicht bei uns erhoben wurden, sowie
                  über das Bestehen einer automatisierten Entscheidungsfindung
                  einschließlich Profiling und ggf. aussagekräftigen
                  Informationen zu deren Einzelheiten verlangen;
                </p>
              </li>
              <li>
                <p>
                  gemäß Art. 16 DSGVO unverzüglich die Berichtigung unrichtiger
                  oder Vervollständigung Ihrer bei uns gespeicherten
                  personenbezogenen Daten zu verlangen;
                </p>
              </li>
              <li>
                <p>
                  gemäß Art. 17 DSGVO die Löschung Ihrer bei uns gespeicherten
                  personenbezogenen Daten zu verlangen, soweit nicht die
                  Verarbeitung zur Ausübung des Rechts auf freie
                  Meinungsäußerung und Information, zur Erfüllung einer
                  rechtlichen Verpflichtung, aus Gründen des öffentlichen
                  Interesses oder zur Geltendmachung, Ausübung oder Verteidigung
                  von Rechtsansprüchen erforderlich ist;
                </p>
              </li>
              <li>
                <p>
                  gemäß Art. 18 DSGVO die Einschränkung der Verarbeitung Ihrer
                  personenbezogenen Daten zu verlangen, soweit die Richtigkeit
                  der Daten von Ihnen bestritten wird, die Verarbeitung
                  unrechtmäßig ist, Sie aber deren Löschung ablehnen und wir die
                  Daten nicht mehr benötigen, Sie jedoch diese zur
                  Geltendmachung, Ausübung oder Verteidigung von
                  Rechtsansprüchen benötigen oder Sie gemäß Art. 21 DSGVO
                  Widerspruch gegen die Verarbeitung eingelegt haben;
                </p>
              </li>
              <li>
                <p>
                  gemäß Art. 20 DSGVO Ihre personenbezogenen Daten, die Sie uns
                  bereitgestellt haben, in einem strukturierten, gängigen und
                  maschinenlesebaren Format zu erhalten oder die Übermittlung an
                  einen anderen Verantwortlichen zu verlangen und
                </p>
              </li>
              <li>
                <p>
                  gemäß Art. 77 DSGVO sich bei einer Aufsichtsbehörde zu
                  beschweren. In der Regel können Sie sich hierfür an die
                  Aufsichtsbehörde Ihres üblichen Aufenthaltsortes oder
                  Arbeitsplatzes oder unseres Sitzes wenden.
                </p>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <h6 class="text-blue mb-3">Widerspruchsrecht</h6>
          <p class="mb-2">
            Sofern Ihre personenbezogenen Daten auf Grundlage von berechtigten
            Interessen gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO verarbeitet werden,
            haben Sie das Recht, gemäß Art. 21 DSGVO Widerspruch gegen die
            Verarbeitung Ihrer personenbezogenen Daten einzulegen, soweit dafür
            Gründe vorliegen, die sich aus Ihrer besonderen Situation ergeben
            oder sich der Widerspruch gegen Direktwerbung richtet. Im letzteren
            Fall haben Sie ein generelles Widerspruchsrecht, das ohne Angabe
            einer besonderen Situation von uns umgesetzt wird.
          </p>
          <p>
            Möchten Sie von Ihrem Widerrufs- oder Widerspruchsrecht Gebrauch
            machen, genügt eine E-Mail an kanzlei@ihgp-steuer.de.
          </p>
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.privacy-policy {
  padding: 160px 0px;
  text-align: justify;

  ul {
    list-style-type: circle;
  }

  ol {
    li {
      &:not(:last-child) {
        margin-bottom: 1rem;
      }

      &::marker {
        color: #57c6ff;
        font-weight: bold;
      }
    }
  }
}
</style>