<template>
  <div>
    <Navbar />
    <PrivacyPolicy />
    <Footer />
  </div>
</template>

<script>
import PrivacyPolicy from "../components/information/PrivacyPolicy.vue";
import Footer from "../components/shared/Footer.vue";
import Navbar from "../components/shared/Navbar.vue";

export default {
  components: {
    Navbar,
    Footer,
    PrivacyPolicy,
  },
  created() {
    const lang = this.$i18n.locale === "tr" ? "" : this.$i18n.locale;
    location.href = `https://flinktax.de/${lang}/datenschutzinformationen`;
  },
};
</script>

<style></style>
